import {axios} from '@/utils/axios';

const RESOURCE_NAME = '/flat-cashback';

export default {
    get_headers(payload) {
        return axios.get(`${RESOURCE_NAME}/header`, {params: payload})
    },
    get_by_header(payload) {
        return axios.get(`${RESOURCE_NAME}/`, {params: {header_id: payload}})
    },
    fill_document(payload) {
        return axios.post(`${RESOURCE_NAME}/fill-document`, payload)
    },
    fill_document_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/fill-document-legacy`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    update_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { header_id: payload })
    },
    remove_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/remove`, { header_id: payload })
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/create`, payload)
    },
    create_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/create`, payload)
    },
}
